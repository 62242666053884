import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { TextField, Button, Container, Grid, Typography } from "@mui/material";
import authApiService from "../services/auth.apiservice";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../components/AlertDailog";
import { isNotValidPetpoojaEmail } from "../services/utilities";
import { useSignIn } from "react-auth-kit";

const Login = () => {
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [title, setTitle] = useState("title");
  const [alertOpen, setAlertOpen] = useState(false);
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const showAlert = (title, message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setTitle(title);
    setAlertOpen(true);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { email: "", password: "" };

    if (email.trim() === "") {
      newErrors.email = "Email is required";
      valid = false;
    } else if (isNotValidPetpoojaEmail(email)) {
      newErrors.email = "Email is Invalid";
      valid = false;
    }

    if (password.trim() === "") {
      newErrors.password = "Password is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };
  const signIn = useSignIn();

  const handleLogin = async () => {
    if (validateForm()) {
      await authApiService
        .signin({ email, password })
        .then((result) => {
          console.log("result", result);
          if (
            result.code == 200 &&
            result?.results?.data?.accessToken != null &&
            result?.results?.data?.accessToken != undefined
          ) {
            saveDetails(result?.results?.data);
          } else {
            toast.error(`Failed to Login : ${result?.message}`);
          }
        })
        .catch((error) => {
          console.error("Failed to login. Error: ", error);
          toast.error(`Failed to Login : ${error}`);
        });
      // if(result){

      // }else{

      // }

      // navigate('/hoPage');
    }
  };
  const saveDetails = (details) => {
    if (details != null) {
      if (
        details.accessToken != undefined &&
        details.accessToken != null &&
        details.accessToken != "" &&
        details._id != undefined &&
        details._id != null &&
        details._id != ""
      ) {
        if (
          signIn({
            token: details.accessToken,
            expiresIn: 864000,
            tokenType: "Bearer",
            authState: { ...details },
          })
        ) {
          localStorage.setItem("_auth", details.accessToken);
          navigate("/hoPage");
        } else {
          console.log("Error");
          toast.error(`Failed to Login`);
        }
      }
    }
  };
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <div style={{ marginTop: 40 }}>
          <Typography component="h1" variant="h5" style={{ marginBottom: 10 }}>
            Petpooja Tasks Admin Login
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={email}
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={password}
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 10 }}
              onClick={handleLogin}
              // onClick={notify}
            >
              Login
            </Button>
          </form>
          <ToastContainer position="top-center" autoClose={1000} />
        </div>
      </Container>
      <AlertDialog
        open={alertOpen}
        onClose={handleAlertClose}
        title={title}
        message={message}
        severity={severity}
      />
    </div>
  );
};

export default Login;
