import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import TablePagination from "@mui/material/TablePagination";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import outletApiService from "../services/outlet.apiservice";
import { useNotification } from "./Notification";
import { Link } from "react-router-dom"; // Import useHistory hook
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GridToolbar, getGridStringOperators } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
// import { useLazyGetAllHeadOfficeQuery } from "../services/redux/hoApi";
import { Box, IconButton, CircularProgress } from "@mui/material";
import hoApiService from "../services/ho.service";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
  root: {
    "& .MuiDataGrid-footerContainer": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid #eee`,
    },
    "& .MuiDataGrid-columnHeader": {
      background: "#f4f4f4",
    },
    "& .MuiDataGrid-colCellTitle": {
      lineHeight: "12px"
    }
  },
});

const Outlettable = ({ reload }) => {

  // const [getAllHeadOffice, { data }]=useLazyGetAllHeadOfficeQuery()

  // console.log(data);





  const classes = useStyles();
  const displayNotification = useNotification();
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredOutlets, setFilteredOutlets] = useState([]);
  const [isfilter, setIsfilter] = useState(false);
  const [filterModelState, setFilterModelState] = useState();
  const [editedOutlet, setEditedOutlet] = useState({
    name: "",
    ownerName: "",
    ownerMobileNumber: "",
    ownerEmail: "",
    address: "",
    zip_code: "",
    city: "",
    state: "",
    category: "restaurant",
    gstNumber: "",
    email: "",
    phone: "",
    subscriptionEndDate: new Date(
      new Date().setMonth(new Date().getMonth() + 1)
    ),
    is_active: true,
    profile: null,
    originType: "external",
    hasPettpoojaID: "",
    petpoojaID: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setCurrentPage(-1);
    setOutlets([]);
  };

  const handleOpenEditModal = (outlet) => {
    setEditedOutlet(outlet);
    setIsEditModalOpen(true);
  };
  const notify = () => toast("Wow so easy!");

  const handleUpdateOutlet = async (id, editedOutlet) => {
    try {
      const result = await outletApiService.updateItem(id, editedOutlet);
      getAllHoData(page, rowsPerPage, true);
      displayNotification("Outlet Updated Succesfully", "sucess");
      handleCloseEditModal();
    } catch (error) {
      displayNotification(
        "Error Occured while updating outlet from outlettable",
        "error"
      );
      console.error("Error Occured while updating WebAdmin : ", error);
    }
  };

  const handleCloseEditModal = () => {
    setEditedOutlet({
      name: "",
      ownerName: "",
      ownerMobileNumber: "",
      ownerEmail: "",
      address: "",
      zip_code: "",
      city: "",
      state: "",
      category: "restaurant",
      gstNumber: "",
      email: "",
      phone: "",
      subscriptionEndDate: new Date(
        new Date().setMonth(new Date().getMonth() + 1)
      ),
      is_active: true,
      profile: null,
      originType: "external",
      hasPettpoojaID: "",
      petpoojaID: "",
    });
    setIsEditModalOpen(false);
  };

  function removeLastNElements(arr, n) {
    if (n >= 0) {
      arr.splice(-n, n);
    } else {
      console.error("Invalid value of N. N should be a non-negative integer.");
    }
  }

  const getAllHoData = async (page, rowsPerPage, isForceCall = false) => {
    if (isfilter != true) {
      setLoading(true);
      try {
        const result = await hoApiService.getAllHo(
          page + 1,
          rowsPerPage,
          "",
          ""
        );
        // console.log(result);
        if (
          result.results.data.hoData != undefined &&
          result.results.data.hoData != null
        ) {
          const x = await result.results.data.hoData.map((item, i) => {
            return {
              ...item,
              i: ++i,
              id: item._id,
              phone: item.phone,
              active: item.is_active,
              employeeList: Array.isArray(item.employeeList)
                ? item.employeeList.length
                : 0,
              orgAccess: Array.isArray(item.orgAccess)
                ? item.orgAccess.length
                : 0,
              createdAt: new Date(item.createdAt).toLocaleDateString(),
            };
          });
          // console.log(x.length);
          setOutlets(x);
          setTotalCount(result.results.data.totalUsers);
        }
      } catch (error) {
        // console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
        setCurrentPage(page);
      }
    } else {
      await filterOutletApi(filterModelState);
    }

    // }
  };

  const filterOutletApi = async (filterModel) => {
    if (filterModel && filterModel.items && filterModel.items.length > 0) {
      let fieldKey = "";
      let filterValue = "";

      let filterItem = filterModel.items[0];
      if (filterItem && filterItem.field) {
        fieldKey = filterItem.field;
      }
      if (filterItem && filterItem.value !== undefined) {
        filterValue = filterItem.value;
      }
      // console.log("call", fieldKey, filterValue);

      const result = await hoApiService.getAllHo(
        page + 1,
        rowsPerPage,
        fieldKey,
        filterValue
      );
      // console.log("filterresult", result.results);
      if (
        result.results.data.hoData != undefined &&
        result.results.data.hoData != null
      ) {
        const x = await result.results.data.hoData.map((item, i) => {
          return {
            ...item,
            i: ++i,
            id: item._id,
            phone: item.phone,
            active: item.is_active,
            employeeList: Array.isArray(item.employeeList)
              ? item.employeeList.length
              : 0,
            orgAccess: Array.isArray(item.orgAccess)
              ? item.orgAccess.length
              : 0,
            createdAt: new Date(item.createdAt).toLocaleDateString(),
          };
        });
        setOutlets(x);
        setTotalCount(result.results.data.totalUsers);
      }
    } else {
      setFilteredOutlets([]);
    }
  };

  const onFilterChange = useCallback(
    async (filterModel) => {
      // console.log("onFilterChange ", filterModel);
      setIsfilter(true);
      // getAllHeadOffice({filterModel})
      setFilterModelState(filterModel);
      await filterOutletApi(filterModel);
    },
    [page, rowsPerPage]
  );

  let columns = [
    { field: "i", headerName: "ID", width: 1, filterable: false },
    {
      field: "profile", headerName: "Profile", flex: 1, filterable: false,
      renderCell: (params) => (
        <div style={{ marginTop: 10 }}>
          <img
            src={params.value}
            alt="img"
            style={{ width: 30, height: 30, borderRadius: "50%" }}
          />
        </div>
      ),
    },
    { field: "hoName", headerName: "HO name", width: 200 },
    { field: "hoCountId", headerName: "HO ID", width: 100 }, //notworking
    { field: "orgAccess", headerName: "Org Access Count", width: 100 }, //notworking
    { field: "city", headerName: "City", width: 100 },
    { field: "employeeList", headerName: "Employee Count", width: 100 }, //notworking
    { field: "ownerName", headerName: "Owners name", width: 125 },
    { field: "ownerMobileNumber", headerName: "Owners phone", width: 125 },
    { field: "ownerEmail", headerName: "Owners Email", width: 180 },
    { field: "address", headerName: "Address", width: 180 },
    { field: "pincode", headerName: "Zip", width: 75 }, //notworking 
    { field: "state", headerName: "Owners state", width: 100 },
    { field: "industryType", headerName: "Industry Type", width: 100 }, //notworking
    { field: "gstNumber", headerName: "GST no", width: 155 },
    { field: "createdAt", headerName: "Create date", width: 180 },
    { field: "is_active", headerName: "Active", width: 65 },
    { field: "originType", headerName: "Origin Type", width: 100 },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 120,
    //   filterable: false,
    //   renderCell: (params) => (
    //     <div key={params.id}>
    //       <Link
    //         to={`/outlets/edit/${params.id}`}
    //         style={{ textDecoration: "none" }}
    //       >
    //         <IconButton
    //           color="primary"
    //           onClick={() => getAllHoData(page, rowsPerPage)}
    //         >
    //           <EditIcon />
    //         </IconButton>
    //       </Link>
    //       <IconButton color="secondary" onClick={() => onDelete(params.id)}>
    //         <DeleteIcon />
    //       </IconButton>
    //     </div>
    //   ),
    // },
  ];

  columns = React.useMemo(
    () =>
      columns.map((col) => {
        return {
          ...col,
          filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === "contains"
          ),
        };
      }),
    [columns]
  );

  const onDelete = async (id) => {
    try {
      const result = await outletApiService.deleteOutlet(id);
      displayNotification("Outlet Deactivated Successfully", "sucess");
      //   getAllHeadOffice( {
      //     page, rowsPerPage
      // })
      getAllHoData(page, rowsPerPage, true);
    } catch (error) {
      displayNotification("Error Occured While deleting Outlet", "error");
      console.error("Error Occured While deleting Outlet : ", error);
    }
  };

  useEffect(() => {
    getAllHoData(page, rowsPerPage);
    // console.log("on page", page);
  }, [page, rowsPerPage]);

  useEffect(() => {
    getAllHoData(page, rowsPerPage);
    // console.log("on currentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    getAllHoData(page, rowsPerPage);
    // console.log("on isfilter", isfilter);
  }, [isfilter]);

  useEffect(() => {
    console.log("called form lazey query");
    // getAllHeadOffice({page,rowsPerPage})
  }, [page, rowsPerPage])

  return (
    <div>
      {/* <pre>{JSON.stringify(data,null,2)}</pre> */}

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <div className={classes.root} style={{ height: "72vh", width: "100%" }}>
            <DataGrid
              rows={outlets}
              columns={columns}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              slots={{ toolbar: GridToolbar }}
            />

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Outlettable;
