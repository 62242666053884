import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Outlets from "./pages/Outlets";
import Appbar from "./components/Appbar";
import WebAdmin from "./pages/Adminusers";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import NotFound from "./pages/NotFound";
import OutletForm from "./components/OutletForm";
import NotificationProvider from "./components/Notification"; // Import the NotificationProvider
import CountrySelector from "./components/CountrySelect";
import HoPage from "./pages/HoPage";
import HoForm from "./components/HoForm";
import HoEditForm from "./components/HoEditForm";
import { RequireAuth } from "react-auth-kit";

function App() {
  return (
    <Router>
      <NotificationProvider>
        {" "}
        {/* Wrap your entire application with NotificationProvider */}
        <Box sx={{ flexGrow: 1 }}>
          {/* <Appbar title={title}/> */}
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route
              path="/dashboard"
              exact
              element={
                <RequireAuth loginPath="/">
                  <Layout title="Dashboard" children={<Dashboard />} />
                </RequireAuth>
              }
            />
            <Route
              path="/hoform"
              exact
              element={
                <RequireAuth loginPath="/">
                  <Layout title="Hoform" children={<HoForm />} />
                </RequireAuth>
              }
            />
            <Route
              path="/hoPage"
              exact
              element={
                <RequireAuth loginPath="/">
                  <Layout title="Hopage" children={<HoPage />} />
                </RequireAuth>
              }
            />
            <Route
              path="/hoeditform/:id"
              exact
              element={
                <RequireAuth loginPath="/">
                  <Layout title="Hoform" children={<HoEditForm />} />
                </RequireAuth>
              }
            />
            <Route
              path="/users"
              exact
              element={
                <RequireAuth loginPath="/">
                  <Layout title="User Details" children={<Users />} />
                </RequireAuth>
              }
            />
            <Route
              path="/outlets"
              exact
              element={
                <RequireAuth loginPath="/">
                  <Layout title="Outlet Details" children={<Outlets />} />
                </RequireAuth>
              }
            />
            <Route
              path="/outlets/add"
              exact
              element={
                <RequireAuth loginPath="/">
                  <Layout
                    title="Add Outlet"
                    children={<OutletForm isAdding={true} />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/outlets/edit/:id"
              exact
              element={
                <RequireAuth loginPath="/">
                  <Layout
                    title="Update Outlet"
                    children={<OutletForm isAdding={false} />}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/webadmins"
              exact
              element={
                <RequireAuth loginPath="/">
                  <Layout title="WebAdmin Details" children={<WebAdmin />} />
                </RequireAuth>
              }
            />
            <Route
              path="/country"
              exact
              element={
                <RequireAuth loginPath="/">
                  <Layout
                    title="WebAdmin Details"
                    children={<CountrySelector />}
                  />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </NotificationProvider>
    </Router>
  );
}

export default App;
